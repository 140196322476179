import axios from 'axios';
import '../scss/app.scss';
import { fpwp_add_button_loader, fpwp_card_details, fpwp_form_validation_message_html, fpwp_get_url_param, fpwp_inject_form_validation_message, fpwp_on_3ds_complete, fpwp_remove_button_loader } from './functions';

$ = jQuery;



/**
 * Post message to window on Stripe 3DS completion
 */
if (fpwp_get_url_param('stripe_3ds_success') == 1) {
    window.top.postMessage('3DS-authentication-complete');
}



/**
 * Listen for Stripe 3DS completion message
 */
window.addEventListener('message', function (e) {
    var iframe_wrapper = $('.sca-3ds');

    iframe_wrapper.addClass('sca-3ds-loading');

    if (e.data.type == 'loaded') {
        iframe_wrapper.removeClass('sca-3ds-loading');
    }

    fpwp_on_3ds_complete(e);
}, false);


async function fpwp_stripe_get_client_secret(amount) {
    let form_data = new FormData;
    form_data.append('action', 'fpwp_stripe_payment_intent');
    form_data.append('amount', amount);

    return axios.post(fpwp_data_card_payment.ajax_admin, form_data);
}


$(document).ready(function () {
    /**
     * Create payment intent
     */
    $('.js-fpwp-payment-form').each(async function () {
        let form = $(this);
        let submit_button = form.find('.js-fpwp-submit-button');
        let card_element = form.find('.js-fpwp-card-element');

        if (!card_element.length) {
            return;
        }

        let client_secret = (await fpwp_stripe_get_client_secret(form.find('input[name="amount"]').val()))?.data.data?.client_secret;

        let stripe = Stripe(fpwp_data_card_payment.stripe_publishable_key);
        let elements = stripe.elements({
            clientSecret: client_secret,
            appearance: {
                theme: 'flat',
                variables: {
                    colorBackground: '#e8e8e8',
                    borderRadius: '4px',
                }
            }
        });

        let paymentElement = elements.create('payment');

        paymentElement.mount(card_element[0]);

        form.on('submit', async function (e) {
            e.preventDefault();

            let form = $(this);
            let button = form.find('button');

            fpwp_add_button_loader();

            button.prop('disabled', true);

            let { error: error, paymentIntent } = await stripe.confirmPayment({
                elements,
                confirmParams: {
                    return_url: fpwp_data_card_payment.stripe_return_url,
                },
                redirect: 'if_required',
            });

            button.prop('disabled', false);
            fpwp_remove_button_loader();

            if (paymentIntent && paymentIntent.status === "succeeded" && !error) {
                fpwp_inject_form_validation_message(form, fpwp_form_validation_message_html('Payment complete!', 'success'));
            } else {
                fpwp_inject_form_validation_message(form, fpwp_form_validation_message_html('Payment failed!', 'error'));
            }
        });
    });
});
