/**
 * Get card details from payment form
 *
 * @param {object} form
 */
export function fpwp_card_details(form) {
    return {
        'name'      : form.find('input[name="name"]').val(),
        'number'    : form.find('input[name="card_number"]').val(),
        'exp_month' : form.find('select[name="card_exp_month"] option:selected').val(),
        'exp_year'  : form.find('select[name="card_exp_year"] option:selected').val(),
        'cvc'       : form.find('input[name="card_cvc"]').val(),
        'zip'       : form.find('input[name="card_zip"]').val()
    };
}



/**
 * Get parameter from URL GET
 *
 * @param {string} name
 */
export function fpwp_get_url_param(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');

    var regex   = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);

    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};



/**
 * Check Stripe PaymentIntent on iFrame verification
 *
 * @param {object} event
 */
export function fpwp_on_3ds_complete(event) {
    if(event.data == '3DS-authentication-complete') {
        // Set up vars
        var iframe_wrapper         = $('.sca-3ds');
        var form                   = iframe_wrapper.prev('form.js-fpwp-payment-form');
        var client_secret_key_elem = form.find('input[name="stripe_secret_key"]');
        var client_secret_key      = client_secret_key_elem.val();

        // Check if payment was made
        var stripe = Stripe(fpwp_data_card_payment.stripe_publishable_key);

        stripe.retrievePaymentIntent(client_secret_key).then(function(result) {
            if(result.error) {
                // Invalid Stripe API key
                fpwp_inject_form_validation_message(form, fpwp_form_validation_message_html('<p>The Stripe API key for this request is invalid.</p>', 'error'));
            }else {
                iframe_wrapper.remove();
                form.show();

                if (result.paymentIntent.status === 'succeeded') {
                    // Success
                    client_secret_key_elem.remove();
                    form.trigger('reset');

                    setTimeout(function() {
                        fpwp_inject_form_validation_message(form, fpwp_form_validation_message_html('<p>Your payment was successful. Thanks!</p>', 'success'));
                    }, 500);
                } else if (result.paymentIntent.status === 'requires_payment_method') {
                    setTimeout(function() {
                        fpwp_inject_form_validation_message(form, fpwp_form_validation_message_html('<p>3D secure payment verification failed.</p>', 'error'));
                    }, 500);
                }
            }
        });

        client_secret_key_elem.remove();
    }
}



/**
 * HTML for validation message
 *
 * @param {object} message
 * @param {string} type
 */
export function fpwp_form_validation_message_html(message, type) {
    var css_class = false;

    if(type == 'error') {
        css_class = 'fpwp-form-notice--error';
    }else if(type == 'success') {
        css_class = 'fpwp-form-notice--success';
    }

    return "<div class='fpwp-form-notice " + css_class + "' style='display: none;'>" + message + "</div>";
}



/**
 * Inject validation into top of form
 * @param {object} form
 * @param {string} message_html
 */
export function fpwp_inject_form_validation_message(form, message_html) {
    if(form.length) {
        form.find('.fpwp-form-notice').slideUp(250);

        setTimeout(function() {
            form.find('.fpwp-form-notice').remove();

            form.prepend(message_html).slideDown(250);
            form.find('.fpwp-form-notice').slideDown(250);
        }, 250);
    }
}



/**
 * Add button loader
 */
export function fpwp_add_button_loader() {
    $('.fpwp-form-action button').addClass('fpwp-btn-has-loader').append('<span class="fpwp-btn-loader"><svg xmlns="http://www.w3.org/2000/svg" class="lds-rolling" preserveAspectRatio="xMidYMid" style="animation-play-state:paused;animation-delay:-.9s;background:0 0" viewBox="0 0 100 100"><circle cx="50" cy="50" r="35" fill="none" stroke="#000" stroke-dasharray="164.934 56.978" stroke-width="10" style="animation-play-state:paused;animation-delay:-.9s" transform="rotate(26.667 50 50)"><animateTransform attributeName="transform" begin="0s" calcMode="linear" dur="0.9s" keyTimes="0;1" repeatCount="indefinite" type="rotate" values="0 50 50;360 50 50"/></circle></svg></span>');
}


/**
 * Remove button loader
 */
export function fpwp_remove_button_loader() {
    $('.fpwp-form-action button').removeClass('fpwp-btn-has-loader');
    $('.fpwp-btn-loader').remove();
}
